<template>
    <section>
        <div style="text-align: right">
            <el-link type="primary" :underline="false" @click="addContract"></el-link>
        </div>
        <div class="table-title">租房合同记录</div>
        <r-e-table ref="communityTableRef" :dataRequest="getContractApartment" :columns="tableColumns" :height="700"
                   :showPagination="false">
            <el-table-column slot="toolbar" label="操作">
                <template slot-scope="{ }">
                    <div class="table-tools"></div>
                </template>
            </el-table-column>
        </r-e-table>

        <layer-add-shops-contract ref="layerAddShopsContract"/>
    </section>
</template>

<script>
    import {contractColumns} from "../../data";
    import {getContractApartment} from "@/api/shops-contract";
    export default {
        name: "contract",
        props: {
            currentShops: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                tableColumns: contractColumns,
            };
        },
        components: {
            layerAddShopsContract: () => import("./components/layer-add-shops-contract"),
        },
        async created() {},
        methods: {
            getContractApartment(params){
                const {uuid} = this.currentShops;
                return getContractApartment({...params,uuid});
            },
            addContract(){
                this.$refs["layerAddShopsContract"].openDialog(this.currentShops);
            }
        }
    }
</script>

<style scoped>

</style>
